import React, { useState } from 'react';
import './App.css';
import ColorChangingSquare from './components/ColorChangingSquare';
import NumberDisplay from './components/NumberDisplay';
import LetterDisplay from './components/LetterDisplay';

function App() {
  const [activeComponent, setActiveComponent] = useState('colorSquare');

  return (
    <div className="App">
      <nav className="bg-gray-800 p-4">
        <ul className="flex justify-center">
          <li className="mx-4">
            <button
              className={`px-3 py-2 rounded text-white ${activeComponent === 'colorSquare' ? 'bg-blue-500' : 'bg-gray-700'}`}
              onClick={() => setActiveComponent('colorSquare')}
            >
              Colors
            </button>
          </li>
          <li>
            <button
              className={`px-3 py-2 rounded text-white ${activeComponent === 'numberDisplay' ? 'bg-blue-500' : 'bg-gray-700'}`}
              onClick={() => setActiveComponent('numberDisplay')}
            >
              Numbers
            </button>
          </li>
          <li>
            <button
              className={`px-3 py-2 rounded text-white ${activeComponent === 'letterDisplay' ? 'bg-blue-500' : 'bg-gray-700'}`}
              onClick={() => setActiveComponent('letterDisplay')}
            >
              Letters
            </button>
          </li>
        </ul>
      </nav>

      <div className="content mt-10">
        {activeComponent === 'colorSquare' && <ColorChangingSquare />}
        {activeComponent === 'numberDisplay' && <NumberDisplay />}
        {activeComponent === 'letterDisplay' && <LetterDisplay />}
      </div>
    </div>
  );
}

export default App;
