import React, { useState, useEffect } from 'react';

const colors = [
    { name: "Black", code: "#000000" },
    { name: "White", code: "#FFFFFF" },
    { name: "Red", code: "#FF0000" },
    { name: "Brown", code: "#A52A2A" },    
    { name: "Green", code: "#008000" },
    { name: "Blue", code: "#0000FF" },
    { name: "Yellow", code: "#FFFF00" },
    { name: "Purple", code: "#800080" },
    { name: "Orange", code: "#FFA500" },
    { name: "Cyan", code: "#00FFFF" },
    { name: "Magenta", code: "#FF00FF" },
    { name: "Lime", code: "#00FF00" },
    { name: "Pink", code: "#FFC0CB" }
];

function ColorChangingSquare() {
  const [color, setColor] = useState(colors[Math.floor(Math.random() * colors.length)]);
  const [boxSize, setBoxSize] = useState('25vw');

  useEffect(() => {
    const interval = setInterval(() => {
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      setColor(randomColor);
    }, 10000);

    const resizeListener = () => {
      const size = Math.min(window.innerWidth, window.innerHeight) * 0.25 + 'px';
      setBoxSize(size);
    };

    window.addEventListener('resize', resizeListener);
    resizeListener(); // Call initially to set size

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <div style={{ width: boxSize, height: boxSize, backgroundColor: color.code }}
           className="flex justify-center items-center">
      </div>
      <p id="colorName" className="mt-5 text-xl text-gray-800">{color.name}</p>
    </div>
  );
}

export default ColorChangingSquare;
