import React, { useState, useEffect } from 'react';

const numbers = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];

const colors = [
    { name: "Black", code: "#000000" },
    { name: "Red", code: "#FF0000" },
    { name: "Brown", code: "#A52A2A" },    
    { name: "Green", code: "#008000" },
    { name: "Blue", code: "#0000FF" },
    { name: "Yellow", code: "#a89d32" },
    { name: "Purple", code: "#800080" },
    { name: "Orange", code: "#FFA500" },
    { name: "Cyan", code: "#00FFFF" },
    { name: "Magenta", code: "#FF00FF" },
    { name: "Lime", code: "#00FF00" },
    { name: "Pink", code: "#FFC0CB" }
];

function NumberDisplay() {
  const [number, setNumber] = useState(Math.floor(Math.random() * 11));
  const [color, setColor] = useState(colors[Math.floor(Math.random() * colors.length)]);

  useEffect(() => {
      const interval = setInterval(() => {
        setNumber(Math.floor(Math.random() * 11));
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        setColor(randomColor);
      }, 10000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <p id="mainCharacter" style={{ color: color.code }}>{number}</p>
      <p id="colorName" className="mt-5 text-xl text-gray-800">{numbers[number]}</p>
    </div>
  );


}

export default NumberDisplay;


